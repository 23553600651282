import { Center, Spinner } from '@chakra-ui/react'
import React, { ReactElement } from 'react'

function Loader(): ReactElement {
  return (
    <Center>
      <Spinner /> Loading
    </Center>
  )
}

export default Loader
