import React, { useEffect } from 'react'
import { withAuthUser, AuthAction } from 'next-firebase-auth'
import { useAuthModalContext } from '../context/auth-modal/context'
import Loader from '../components/general/Loader'

const Login = () => {
  const { openAuthModal } = useAuthModalContext()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      openAuthModal('logIn')
    }
  }, [openAuthModal])

  return <></>
}

export default withAuthUser({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
  whenUnauthedBeforeInit: AuthAction.RENDER,
  whenUnauthedAfterInit: AuthAction.RENDER,
  LoaderComponent: Loader,
})(Login)
